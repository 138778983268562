import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, ReplaySubject, of, combineLatest } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { CustomValidators } from '../../../../core/utils/custom-validators';
import { BreadcrumbItem } from '../../../../core/components/breadcrumbs/breadcrumb-item.interface';

import {
  Pharmacy,
  MusubiPharmacyInfo,
  PharmacyRegistration,
} from '../../../../core/models';
import { PharmacyService } from '../../services/pharmacy.service';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-pharmacy-form',
  templateUrl: './pharmacy-form.component.html',
})
export class PharmacyFormComponent implements OnInit {
  @Input() actionName: string;
  @Input() isFailed$: BehaviorSubject<boolean>;

  @Input() errorMessage$: BehaviorSubject<string>;
  @Input() pharmacy: Pharmacy;
  @Input() breadcrumbs: BreadcrumbItem[];

  @Output() submitEvent = new EventEmitter<PharmacyRegistration>();

  readonly pharmacyId = this.route.snapshot.paramMap.get('id');
  readonly officialAccountId =
    this.route.snapshot.paramMap.get('officialAccountId');

  readonly musubiPharmacies$ = new ReplaySubject<MusubiPharmacyInfo[]>(1);

  pharmacyForm = this.fb.group({
    musubiPharmacy: ['', Validators.required],
    name: ['', Validators.required],
    address: ['', Validators.required],
    tel: ['', [Validators.required, CustomValidators.tel]],
    medicalInstitutionCode: [
      '',
      [Validators.required, CustomValidators.medicalInstitutionCode],
    ],
    zipCode: ['', [Validators.required, CustomValidators.zipCode]],
    isContractedMusubi: [true, [Validators.required]],
    isContractedMusubiWebMedicalQuestionnaire: [false, [Validators.required]],
    musubiPharmacyId: [''],
    latitude: ['', [CustomValidators.float]],
    longitude: ['', [CustomValidators.float]],
  });

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private pharmacyService: PharmacyService
  ) {
    this.pharmacyForm
      .get('medicalInstitutionCode')
      .valueChanges.pipe(
        mergeMap((code: string) => {
          if (code.length === 10) {
            return this.pharmacyService.getMusubiPharmacyInfo$(code);
          }
          return of([]);
        })
      )
      .subscribe((musubiPharmacies) =>
        this.musubiPharmacies$.next(musubiPharmacies)
      );

    combineLatest([
      this.pharmacyForm.get('musubiPharmacy').valueChanges,
      this.musubiPharmacies$,
    ]).subscribe(([musubiPharmacyId, musubiPharmacies]) => {
      const musubiPharmacy = musubiPharmacies.find(
        (pharmacy) => pharmacy.pharmacy_id === musubiPharmacyId
      );

      if (!musubiPharmacy) {
        this.pharmacyForm.patchValue({
          name: '',
          address: '',
          tel: '',
          zipCode: '',
          isContractedMusubi: false,
          isContractedMusubiWebMedicalQuestionnaire: false,
          musubiPharmacyId: '',
          longitude: '',
          latitude: '',
        });
        return;
      }

      this.pharmacyForm.patchValue({
        name: musubiPharmacy.pharmacy_name,
        address: musubiPharmacy.address,
        tel: musubiPharmacy.tel,
        zipCode: musubiPharmacy.zip_code,
        isContractedMusubi: musubiPharmacy.is_contracted_musubi,
        isContractedMusubiWebMedicalQuestionnaire: musubiPharmacy.is_contracted_musubi_web_medical_questionnaire,
        musubiPharmacyId: musubiPharmacy.pharmacy_id,
        latitude: '',
        longitude: '',
      });
    });
  }

  submitPharmacy(): void {
    if (this.pharmacyForm.invalid) {
      return;
    }

    const medicalInstitutionCode = this.pharmacyForm.get(
      'medicalInstitutionCode'
    ).value;
    const musubiPharmacyId = this.pharmacyForm.get('musubiPharmacy').value;
    const is_contracted_musubi =
      this.pharmacyForm.get('isContractedMusubi').value;
    const is_contracted_musubi_web_medical_questionnaire = this.pharmacyForm.get('isContractedMusubiWebMedicalQuestionnaire').value;
    const longitude = this.pharmacyForm.get('longitude').value;
    const latitude = this.pharmacyForm.get('latitude').value;

    this.submitEvent.emit({
      medical_institution_code: medicalInstitutionCode,
      musubi_pharmacy_id: musubiPharmacyId,
      is_contracted_musubi: is_contracted_musubi,
      is_contracted_musubi_web_medical_questionnaire: is_contracted_musubi_web_medical_questionnaire,
      longitude: longitude,
      latitude: latitude,
    });
  }

  get name(): AbstractControl {
    return this.pharmacyForm.get('name');
  }

  get address(): AbstractControl {
    return this.pharmacyForm.get('address');
  }

  get tel(): AbstractControl {
    return this.pharmacyForm.get('tel');
  }

  get longitude(): AbstractControl {
    return this.pharmacyForm.get('longitude');
  }

  get latitude(): AbstractControl {
    return this.pharmacyForm.get('latitude');
  }

  get medicalInstitutionCode(): AbstractControl {
    return this.pharmacyForm.get('medicalInstitutionCode');
  }

  get zipCode(): AbstractControl {
    return this.pharmacyForm.get('zipCode');
  }

  get isContractedMusubi(): AbstractControl {
    return this.pharmacyForm.get('isContractedMusubi');
  }

  get isContractedMusubiWebMedicalQuestionnaire(): AbstractControl {
    return this.pharmacyForm.get('isContractedMusubiWebMedicalQuestionnaire');
  }

  ngOnInit(): void {
    if (this.pharmacy) {
      this.pharmacyForm.patchValue({
        name: this.pharmacy.name,
        address: this.pharmacy.address,
        tel: this.pharmacy.tel,
        zipCode: this.pharmacy.zip_code,
        medicalInstitutionCode: this.pharmacy.medical_institution_code,
        musubiPharmacy: this.pharmacy.musubi_pharmacy_id,
        isContractedMusubi: this.pharmacy.is_contracted_musubi,
        isContractedMusubiWebMedicalQuestionnaire: this.pharmacy.is_contracted_musubi_web_medical_questionnaire,
      });
    }

    this.pharmacyForm.get('isContractedMusubi')?.valueChanges.subscribe(isContracted => {
      const musubiWebMedicalQuestionnaireControl = this.pharmacyForm.get('isContractedMusubiWebMedicalQuestionnaire');
      if (isContracted) {
        musubiWebMedicalQuestionnaireControl?.enable();
      } else {
        musubiWebMedicalQuestionnaireControl?.disable();
        const defaultValue = this.pharmacy ? this.pharmacy.is_contracted_musubi_web_medical_questionnaire : false;
        musubiWebMedicalQuestionnaireControl?.setValue(defaultValue);
      }
    });
  }
}
